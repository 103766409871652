<template>
  <div class="row">
    <div class="col-12">
      <h2>Bairros</h2>
      <Card>
        <div class="row">
          <div class="col-md-8"></div>
          <div
            class="col-md-4 form-row d-flex justify-content-sm-end flex-wrap"
          >
            <router-link to="/districts/new">
              <base-button type="warning add-button">
                Adicionar
              </base-button>
            </router-link>
            <base-input
              type="text"
              v-model="search"
              placeholder="Busca"
              @keyup.enter.native="handleSearch"
            />
          </div>
        </div>

        <DistrictTable :data="districts.data" :removeIndex="removeIndex" />

        <div class="pagination-div">
          <base-pagination
            type="warning"
            :page-count="districts.last_page"
            v-model="current_page"
            @input="handleChangePage"
          />
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Cards/Card'
import DistrictTable from '@/pages/District/DistrictTable'
import { BasePagination, BaseInput } from '@/components'

export default {
  name: 'DistrictPage',
  async created () {
    await this.makePagination(this.current_page)
  },
  data: () => ({
    districts: false,
    current_page: 1,
    search: ''
  }),
  methods: {
    handleChangePage (next_page) {
      this.makePagination(next_page)
    },
    removeIndex (id) {
      const districtIndex = this.districts.data.findIndex(d => d.id === id)
      this.districts.data.splice(districtIndex, 1)
    },
    handleSearch () {
      let search = null
      if (this.search) {
        search = this.search
      }

      // reset search
      this.makePagination(1, search)
    },
    async makePagination (page, search = null) {
      let url = `/districts?page=${page}`

      if (search) {
        url += `&search=${search}`
      }

      const {
        data: { data }
      } = await this.$http.get(url)
      const districts = data

      this.districts = districts
      this.current_page = districts.current_page
    }
  },
  components: { Card, DistrictTable, BasePagination, BaseInput },
  watch: {
    current_page () {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style scoped>
.pagination-div {
  display: flex;
  justify-content: flex-end;
}

.add-button {
  height: 40px;
  margin: 0;
  margin-right: 15px;
}
</style>
