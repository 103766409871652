<template>
  <base-table v-if="data" :data="data" thead-classes="text-primary">
    <template slot="columns">
      <th>#</th>
      <th>Nome</th>
      <th>Cidade</th>
      <th class="text-right">Status</th>
      <th class="text-right">Ações</th>
    </template>

    <template slot-scope="{ row }">
      <td>{{ row.id }}</td>
      <td>{{ row.name }}</td>
      <td>{{ row.city.name }}</td>

      <td class="text-right">
        <span v-if="row.is_active === 1" style="color: green;">
          <i class="tim-icons icon-check-2"></i>
        </span>

        <span v-if="row.is_active === 0" style="color: red;">
          <i class="tim-icons icon-simple-remove"></i>
        </span>
      </td>

      <td class="text-right">
        <el-tooltip
          content="Editar"
          effect="light"
          :open-delay="300"
          placement="top"
        >
          <router-link :to="`districts/new/${row.id}`">
            <base-button type="info" icon size="sm" class="btn-link">
              <i class="tim-icons icon-pencil"></i>
            </base-button>
          </router-link>
        </el-tooltip>

        <el-tooltip
          content="Remover"
          effect="light"
          :open-delay="300"
          placement="top"
        >
          <base-button
            type="danger"
            icon
            size="sm"
            class="btn-link"
            @click="removeDistrict(row)"
          >
            <i class="tim-icons icon-trash-simple"></i>
          </base-button>
        </el-tooltip>
      </td>
    </template>
  </base-table>
</template>
<script>
import { BaseTable } from '@/components'

export default {
  name: 'DistrictTable',
  props: ['data', 'removeIndex'],
  methods: {
    async removeDistrict (district) {
      await this.$http.delete(`/districts/${district.id}`)
      this.removeIndex(district.id)
    }
  },
  components: { BaseTable }
}
</script>
